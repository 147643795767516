/* Reset default browser styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

/* Box sizing */
* {
  box-sizing: border-box;
}

/* Global styles */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* About component styles */
/* .founder-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.founder-info {
  flex: 1;
  padding: 20px;
}

.founder-photo {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.founder-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
} */

/* .carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
} */

/* .carousel-item {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
} */

/* .carousel-image {
  width: 50%;
  height: 20%;
  margin: auto;
  border-radius: 8px;
} */

.artha-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.artha-content {
  flex: 1;
  padding: 20px;
}

.artha-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.artha-text {
  margin-bottom: 20px;
}

.artha-svg {
  width: 200px;
  height: auto;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .founder-section {
    flex-direction: column;
    align-items: center;
  }

  .founder-info {
    padding: 0;
    text-align: center;
    margin-top: 20px;
  }

  .carousel-item {
    flex: 0 0 calc(50% - 20px);
  }

  .artha-content {
    padding: 0;
  }

  .my_universe {
    display: none;
  }
}
